<template>
  <div class="login">
    <div class="background">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half">
            <div class="box">
              <div class="columns is-centered">
                <div class="column is-8 is-flex is-justify-content-center">
                  <img :src="mySVG" />
                </div>
              </div>
              <div class="columns is-centered">
                <div
                  class="column is-10 is-centered is-flex is-justify-content-center"
                >
                  <span class="message-thanks title-l bold">{{
                    $t("COME_BACK.TITLE")
                  }}</span>
                </div>
              </div>
              <div class="columns is-centered">
                <div class="column is-8 is-flex is-justify-content-center">
                  <p class="text-l gray">
                    {{ $t("COME_BACK.SUBTITLE") }}
                  </p>
                </div>
              </div>
              <div class="columns is-centered">
                <div class="column is-8 is-flex is-justify-content-center">
                  <button class="button btn-go-back" @click="volver()">
                    <span
                      ><b>{{ $t("THANKS_YOU.CLOSE") }}</b></span
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "vuelvePronto",
  components: {},
  data() {
    return {
      mySVG: require("../assets/icons/comebacksoon.svg"),
    };
  },
  methods: {
    volver() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";

.background {
  position: absolute;
  background: url("../assets/img/Fondo-registro.jpg") no-repeat center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  /* background-attachment: fixed; */
  width: 100%;
  height: 100%;
}

.background:before {
  content: " ";
  position: absolute;
  top: 0;
  z-index: 0;

  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
}

.box {
  background-color: white;
  border-radius: 0.1rem;
  padding: 3.5rem 0;
  margin-top: 30%;
  margin-bottom: 2rem;
}

.message-thanks {
  color: black;
  line-height: 40px;
  text-align: center;
}

.btn-go-back {
  background-color: $primary;
  color: white;
  height: 60px;
  width: 100%;
  border-radius: 10px;
}

.btn-go-back:hover {
  color: white;
}

.text-l {
  text-align: center;
}
</style>
